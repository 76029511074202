@import './scss/var.scss';
@import './scss/reset.scss';
@import './node_modules/bootstrap-4-grid/scss/grid.scss';
@import './scss/fonts.scss';
@import './scss/global.scss';
@import './scss/forms.scss';
@import './scss/nav.scss';
@import './scss/text.scss';

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  font-size: 62.5%;
}

body {
  font-family: $font-family-regular, sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-size: 17px;
  line-height: 1.8;
  width: 100%;
  // overflow-x: hidden;
}

body.home {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

body.loading {
  background: $primary;
}

body,
body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body .guigui {
  position: fixed;
  top: 0;
  right: 0;
}

body.webgl-over {
  cursor: pointer;
}

.content-section {
  &,
  .inner-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.logo-grid {
  margin-bottom: -15px;

  ul {
    li {
      list-style: none;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 20px;
      padding-left: 20px;
      width: 33.33%;

      img {
        width: 100%;
      }

      @media (max-width: map-get($grid-breakpoints, sm) - 1) {
        &:nth-child(3n + 1) {
          padding-left: 0;
          padding-right: 40px;
        }

        &:nth-child(3n) {
          padding-left: 40px;
          padding-right: 0;
        }

        &:nth-child(3n + 1):last-child {
          padding-right: 20px;
          padding-left: 20px;
        }
      }

      @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1) {
        width: 20%;

        padding-left: 10px;
        padding-right: 10px;

        &:nth-child(5n + 1) {
          padding-left: 0;
          padding-right: 20px;
        }

        &:nth-child(5n) {
          padding-left: 20px;
          padding-right: 0;
        }
      }

      @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1) {
        width: 20%;

        padding-left: 25px;
        padding-right: 25px;
      }

      @media (min-width: map-get($grid-breakpoints, lg)) {
        padding-left: 15px;
        padding-right: 15px;
        width: 16.66666667%;
      }
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      &:nth-child(6n + 1) {
        margin-left: -15px;
      }
    }
  }
}

.regular-page {
  background: white;
  width: 100%;
  position: relative;
  padding-top: 300px;
  color: $text-color;
  transition: padding-top 0.2s ease-out;

  &.pt-sm {
    padding-top: 120px;

    @media (max-width: map-get($grid-breakpoints, md) - 1) {
      padding-top: 90px;
    }
  }
}
