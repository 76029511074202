$primary: #81b0cd;
$secondary: #0e2c43;
$success: #43cf59;
$text-color: #0e2c43;
$white: #fff;
$grey-100: #ccc;
$black: #000;

// Responsive Breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1084px,
    xl: 1268px
);

// Grid containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 1054px,
    xl: 1054px
);

$max-fluid-container-width: 1440px;

$grid-columns: 12;
$grid-gutter-width: 60px;

$font-family-Light: 'Apercu-Light';
$font-family-regular: 'Apercu-Regular';
$font-family-medium: 'Apercu-Medium';
$heading-font-family: 'tt-commons-pro', sans-serif;
$heading-font-weight: normal;